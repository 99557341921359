<template>
  <div role="main" :id="getRdStationId()"></div>
</template>

<script>
export default {
  methods: {
    getRdStationId() {
      switch (this.$route.params.lang) {
        case "pt":
          return "website-formulario-portugues-83af64d0d0f8109a1174";
        case "pl":
          return "website-formulario-polaco-b723379d3cad20e14ed8";
        case "zh":
          return "website-formulario-chines-5a538dc7941dc8f5c23a";
        case "es":
          return "website-formulario-espanol-df611be999a300f77476";
        case "sv":
          return "website-formulario-sueco-dab6f72834bfffd0f29a";
        case "ru":
          return "website-formulario-ruso-ba6c847d1876ee63241d";
        default:
          return "website-4ae0021dd7491b575692";
      }
    },
    initRdStationForm() {
      const script = document.createElement('script');
      script.src = 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
      script.onload = () => {
        if (window.RDStationForms) {
          new window.RDStationForms(this.getRdStationId(), 'null').createForm();
        } else {
          console.error('RDStationForms not loaded properly');
        }
      };
      document.head.appendChild(script);
    }
  },
  mounted() {
    this.initRdStationForm();
  }
};
</script>
